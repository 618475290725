import $ from "jquery";

class Server {
  constructor() {
    this._HOST = "/api/";
  }

  login(callback, code = "") {
    $.ajax({
      url: this._HOST + "login",
      type: "GET",
      cache: false,
      data: {
        code: code,
      },
      success: callback,
    });
  }

  logout(callback) {
    $.ajax({
      url: this._HOST + "logout",
      type: "GET",
      success: callback,
    });
  }

  getRaces(callback) {
    $.ajax({
      url: this._HOST + "getRaces",
      cache: false,
      type: "GET",
      success: callback,
    });
  }

  getFullRaceInfo(callback, id) {
    $.ajax({
      url: this._HOST + "getFullRaceInfo",
      cache: false,
      type: "GET",
      data: {
        code: id,
      },
      success: callback,
    });
  }

  changeRaceOption(callback, race) {
    $.ajax({
      url: this._HOST + "changeRaceInfo",
      cache: false,
      type: "GET",
      data: { ...race },
      success: callback,
    });
  }

  deleteRace(callback, id) {
    $.ajax({
      url: this._HOST + "deleteRace",
      cache: false,
      type: "GET",
      data: { code: id },
      success: callback,
    });
  }

  createRace(callback, data) {
    $.ajax({
      url: this._HOST + "createRace",
      cache: false,
      type: "POST",
      data: data,
      success: callback,
    });
  }
}

export default Server;
