import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/home";
import Race from "./pages/race";
import Error from "./pages/error";

// --------------- STYLE ---------------------

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// -------------- MAIN FUNCTION --------------

export const routes = {
  login: { localUrl: "/login", publicUrl: "/login", component: <Login /> },
  home: { localUrl: "/", publicUrl: "/", component: <Home /> },
  race: { localUrl: "/race/:id", publicUrl: "/race/", component: <Race /> },
  error: { localUrl: "*", component: <Error /> },
};

function App() {
  return (
    <Router>
      <Switch>
        {Object.values(routes).map((route, index) => (
          <Route key={index} path={route.localUrl} exact={true}>
            {route.component}
          </Route>
        ))}
      </Switch>
    </Router>
  );
}

export default App;
