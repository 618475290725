import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../App";
import Server from "../../components/Server";

// -------------- STYLE & MEDIA -------------

import flag from "../../img/flag.png";
import { ReactComponent as Arrow } from "../../img/icon/arrow.svg";

import "./style.css";

function Login() {
  let history = useHistory();
  const [code, setCode] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLogged, setIsLogged] = useState("start");

  useEffect(() => {
    if (firstLoad) {
      new Server().login((response) => setIsLogged(response.isLogged));
      setFirstLoad(false);
    } else {
      if (isLogged != "start") {
        if (isLogged) history.push(routes.home.publicUrl);
        else {
          setIsLogged("start");
          setCode("");
        }
      }
    }
  }, [isLogged]);

  return (
    <div className="page full-screen centered" id="login">
      <img src={flag} alt="Flag" className="flag left" />
      <img src={flag} alt="Flag" className="flag right" />
      <div className="content text-center">
        <h1 className="title">CnvRaceDirection</h1>
        <br />
        <input
          type="password"
          name="code"
          className="dark-gray"
          placeholder="Inserisci il codice..."
          autoComplete="off"
          value={code}
          onKeyDown={(e) => {
            if (e.code == "Enter")
              new Server().login((response) => {
                setIsLogged(response.isLogged);
              }, code);
          }}
          onChange={(e) => setCode(e.target.value)}
        />
        <button
          className="red"
          onClick={() =>
            new Server().login((response) => {
              setIsLogged(response.isLogged);
            }, code)
          }
        >
          <Arrow fill="white" />
        </button>
      </div>
    </div>
  );
}

export default Login;
