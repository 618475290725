import React from "react";

// --------------- STYLE ----------------

import "./style.css";

function Error() {
  return <div>Error</div>;
}

export default Error;
