import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Server from "../Server";
import { routes } from "../../App";

// -------------- STYLE -------------

import "./style.css";

function Authenticated(props) {
  let children = props.children;
  const [isLogged, setIsLogged] = useState("start");
  const history = useHistory();

  useEffect(() => {
    if (isLogged == "start") {
      new Server().login((response) => setIsLogged(response.isLogged));
    } else if (!isLogged) {
      history.push(routes.login.publicUrl);
    }
  }, [isLogged]);

  return (
    <>
      {isLogged == "start" ? (
        <div className="full-screen centered">
          <p>Caricamento...</p>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default Authenticated;
