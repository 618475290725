import React, { useState, useEffect } from "react";
import Select from "react-select";
import ScrollContainer from "react-indiana-drag-scroll";
import Server from "../../components/Server";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../App";

// -------------- STYLE -------------

import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Edit } from "../../img/icon/edit.svg";
import { ReactComponent as Tick } from "../../img/icon/tick.svg";
import { ReactComponent as X } from "../../img/icon/x.svg";
import { ReactComponent as Add } from "../../img/icon/add.svg";
import { ReactComponent as Arrow } from "../../img/icon/arrow.svg";
import { ReactComponent as Download } from "../../img/icon/download.svg";
import { ReactComponent as Delete } from "../../img/icon/delete.svg";
import "./style.css";

let update = true;

function Race() {
  const [editable, setEditable] = useState(false);
  const [undo, setUndo] = useState(true);
  const [download, setDownload] = useState(false);
  const [raceInfo, setRaceInfo] = useState({});
  const [filters, setFilters] = useState({});
  let { id } = useParams();
  let history = useHistory();

  useEffect(() => {
    let raceCode = window.sessionStorage.getItem("raceCode");
    if (raceCode == id) {
      new Server().getFullRaceInfo(getRaceInfo, raceCode);
      const interval = setInterval(() => {
        new Server().getFullRaceInfo(getRaceInfo, raceCode);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    let raceCode = window.sessionStorage.getItem("raceCode");
    if (raceCode == id) getRaceInfo(raceCode);
  }, []);

  useEffect(() => {
    update = editable;
  }, [editable]);

  function restore() {
    setEditable(false);
    setUndo(!undo);
  }

  function getRaceInfo({ data }) {
    if (!update) {
      setRaceInfo(data);
    }
  }

  function editRace() {
    new Server().changeRaceOption(() => {}, {
      code: raceInfo["generality"]["race_code"],
      ...raceInfo["generality"],
      penalities: raceInfo["penalities"],
    });
    setEditable(false);
  }

  function changeHandler(e) {
    let inputName = e.target.name;
    let inputValue = e.target.value;

    setRaceInfo({
      ...raceInfo,
      generality: { ...raceInfo["generality"], [inputName]: inputValue },
    });
  }

  function deleteRace() {
    new Server().deleteRace(() => {
      history.push(routes.home.publicUrl);
    }, raceInfo["generality"]["race_code"]);
  }

  return (
    <>
      {raceInfo != undefined ? (
        <Container fluid id="race" className="page">
          <Container>
            <br />
            <h1>CnvRaceDirection</h1>
            <br />
            {editable ? (
              <>
                <input
                  name="name"
                  className="dark-gray mb-2"
                  hidden={!editable}
                  value={raceInfo["generality"]["name"]}
                  onChange={(e) => changeHandler(e)}
                />
                <br />
                <input
                  name="date"
                  className="dark-gray mb-2"
                  hidden={!editable}
                  value={raceInfo["generality"]["date"]}
                  onChange={(e) => changeHandler(e)}
                />
                <br />
              </>
            ) : (
              <>
                <h2 contentEditable={editable}>
                  <Arrow
                    fill="var(--second-color)"
                    width="18px"
                    height="18px"
                    className="mb-1 mr-2"
                    style={{ transform: "rotate(180deg)" }}
                    onClick={() => history.push(routes.home.publicUrl)}
                  />
                  {raceInfo["generality"] != undefined
                    ? raceInfo["generality"].name
                    : "Caricamento..."}
                </h2>
                <h4 className="sub-title">
                  {raceInfo["generality"] != undefined
                    ? raceInfo["generality"].date +
                      " - " +
                      raceInfo["generality"].race_code
                    : "Caricamento..."}
                </h4>
              </>
            )}
            <br />
            <Row>
              <Col md="8">
                <Filters
                  filters={{ filters, setFilters }}
                  pilots={raceInfo["pilots"]}
                />
              </Col>
              <Col md="4" className="text-right">
                {editable ? (
                  <div>
                    <X
                      width="15px"
                      height="15px"
                      className="mr-2"
                      onClick={restore}
                    />
                    <Tick
                      width="30px"
                      height="30px"
                      className="mr-3"
                      onClick={() => {
                        editRace();
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <Download
                      width="20px"
                      height="20px"
                      className="mr-3"
                      fill="var(--second-color)"
                      onClick={() => setDownload(!download)}
                    />
                    <Edit
                      width="20px"
                      height="20px"
                      className="mr-3"
                      onClick={() => setEditable(true)}
                    />
                    <Delete
                      width="20px"
                      height="20px"
                      className="mr-3"
                      fill="var(--second-color)"
                      onClick={deleteRace}
                    />
                  </>
                )}
              </Col>
            </Row>
            <br />
          </Container>
          <Table
            editable={editable}
            undo={undo}
            download={download}
            raceInfo={raceInfo}
            filters={filters}
            setRaceInfo={setRaceInfo}
          />
        </Container>
      ) : (
        "Caricamento..."
      )}
    </>
  );
}

function Filters(props) {
  let { filters, setFilters } = props["filters"];
  let pilots = props["pilots"] != undefined ? props["pilots"] : [];

  let nameOptions = "abcdefghijklmnopqrstwxyz".split("").map((letter) => {
    return { value: letter, label: letter };
  });

  let classOptions = [...new Set(pilots.map((pilot) => pilot["class"]))];
  classOptions = classOptions.map((option) => {
    return { value: option, label: option };
  });

  let numberOptions = pilots.map((pilot) => {
    return { value: pilot["number"], label: pilot["number"] };
  });

  return (
    <Col>
      <Row>
        <Col md="1" className="centered mr-3">
          <h6 className="mb-0">Filtri: </h6>
        </Col>
        <Col md="3">
          <Select
            name="letter"
            options={nameOptions}
            className="select"
            isClearable={true}
            placeholder="Iniziale"
            onChange={(e) => {
              if (e != undefined)
                setFilters({ ...filters, letter: e["value"] });
              else setFilters({ ...filters, letter: undefined });
            }}
          ></Select>
        </Col>
        <Col md="3">
          <Select
            name="class"
            options={classOptions}
            className="select"
            isClearable={true}
            placeholder="Classe"
            onChange={(e) => {
              if (e != undefined) setFilters({ ...filters, class: e["value"] });
              else setFilters({ ...filters, class: undefined });
            }}
          ></Select>
        </Col>
        <Col md="3">
          <Select
            name="number"
            options={numberOptions}
            className="select"
            isClearable={true}
            placeholder="Numero"
            onChange={(e) => {
              if (e != undefined)
                setFilters({ ...filters, number: e["value"] });
              else setFilters({ ...filters, number: undefined });
            }}
          ></Select>
        </Col>
      </Row>
    </Col>
  );
}

function Table(props) {
  let editable = props["editable"];
  let undo = props["undo"];
  let download = props["download"];
  let raceInfo = props["raceInfo"];
  let setRaceInfo = props["setRaceInfo"];
  let filters = props["filters"];

  const [firstLoad, setFirstLoad] = useState(true);
  const [previousState, setPreviousState] = useState({});
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      let titles = Object.values(raceInfo["penalities"]).map((item) => item);
      titles = "Numero,Nome,Classe, " + titles.toString() + "\n";
      let pilots = "";
      raceInfo["pilots"].map((item) => {
        let newItem = {
          number: item.number,
          name: item.fullname,
          class: item.class,
          penalities: [...Object.values(item.penalities)],
        };
        newItem = Object.values(newItem).toString() + "\r\n";
        pilots += newItem;
      });

      let data = "data:text/csv;charset=utf-8," + titles + pilots;
      let encodedUri = encodeURI(data);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        raceInfo["generality"]["name"] +
          " - " +
          raceInfo["generality"]["date"] +
          ".csv"
      );
      link.click();
    } else setFirstLoad(false);
  }, [download]);

  useEffect(() => {
    if (editable) {
      setPreviousState({
        ...raceInfo,
      });
    }
  }, [editable]);

  useEffect(() => {
    if (!firstRender) setRaceInfo(previousState);
    else setFirstRender(false);
  }, [undo]);

  function changeHandler(e) {
    let inputName = e.target.name;
    let inputValue = e.target.value;

    setRaceInfo({
      ...raceInfo,
      penalities: { ...raceInfo["penalities"], [inputName]: inputValue },
    });
  }

  function deletePenality(name) {
    let headersClone = { ...raceInfo["penalities"] };
    delete headersClone[name];

    let driverListClone = [];
    raceInfo["pilots"].forEach((pilot) =>
      driverListClone.push({ ...pilot, penalities: { ...pilot.penalities } })
    );

    driverListClone.map((pilot) => {
      delete pilot["penalities"][name];
    });

    setRaceInfo({
      ...raceInfo,
      penalities: headersClone,
      pilots: driverListClone,
    });
  }

  function addPenality() {
    let penalityArray = Object.keys(raceInfo["penalities"]);

    if (penalityArray.length < 8) {
      let id = String.fromCharCode((Math.random() * 100) % 255);
      if (!Object.keys(raceInfo["penalities"]).includes(id)) {
        let driverListClone = [];
        raceInfo["pilots"].forEach((pilot) =>
          driverListClone.push({
            ...pilot,
            penalities: { ...pilot.penalities, [id]: "0" },
          })
        );
        setRaceInfo({
          ...raceInfo,
          penalities: { ...raceInfo.penalities, [id]: "Nuova" },
          pilots: driverListClone,
        });
      } else addPenality();
    }
  }

  return (
    <>
      {raceInfo["penalities"] == undefined ? (
        <div className="full-screen centered">Caricamento...</div>
      ) : (
        <div id="table-container" className="mb-4">
          <ScrollContainer>
            <table id="livetiming">
              <thead>
                <tr>
                  <th className="red-cell">Pilota</th>
                  {Object.keys(raceInfo["penalities"]).map((value, index) => {
                    let editableClass = editable ? "editable" : "";
                    return (
                      <th key={index} className={"red-cell " + editableClass}>
                        <div className="inline-flex">
                          {editable ? (
                            <>
                              <input
                                type="text"
                                name={value}
                                value={raceInfo["penalities"][value]}
                                onChange={changeHandler}
                                onClick={(e) => e.target.focus()}
                                readOnly={!editable}
                                size={
                                  raceInfo["penalities"][value].length == 0
                                    ? 1
                                    : raceInfo["penalities"][value].length
                                }
                              />
                              {editableClass != "" ? (
                                <div onClick={() => deletePenality(value)}>
                                  <X
                                    width="10px"
                                    height="10px"
                                    className="mb-1"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <h6 className="word-break m-0">
                              {raceInfo["penalities"][value]}
                            </h6>
                          )}
                        </div>
                      </th>
                    );
                  })}
                  {(Object.keys(raceInfo["penalities"]).length < 8) &
                  editable ? (
                    <th
                      className={editable ? "red-cell editable" : "red-cell"}
                      onClick={addPenality}
                    >
                      <Add
                        fill="white"
                        width="18px"
                        height="18px"
                        className="m-0"
                      />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {
                  //prints row for each pilotscreen
                  raceInfo["pilots"].map((pilot, index) => {
                    let filtersOK = true;
                    if (
                      (filters["letter"] !=
                        pilot["fullname"][0].toLowerCase()) &
                      (filters["letter"] != undefined)
                    )
                      filtersOK = false;
                    if (
                      (filters["class"] != pilot["class"]) &
                      (filters["class"] != undefined)
                    )
                      filtersOK = false;
                    if (
                      (filters["number"] != pilot["number"]) &
                      (filters["number"] != undefined)
                    )
                      filtersOK = false;
                    if (!filtersOK) return "";
                    else
                      return (
                        <tr key={index}>
                          <td className="pilot-info white-cell">
                            <div className="text-left ml-3 mr-3">
                              <h6 className="mb-0 overflow-hidden">
                                {pilot["number"] + " - " + pilot["fullname"]}
                              </h6>
                              <p className="thin">Classe: {pilot["class"]}</p>
                            </div>
                          </td>
                          {
                            //print data of the pilot
                            Object.values(pilot["penalities"]).map(
                              (value, index) => {
                                return (
                                  <td key={index} className="white-cell">
                                    <h6 className="m-0">{value}</h6>
                                  </td>
                                );
                              }
                            )
                          }
                        </tr>
                      );
                  })
                }
              </tbody>
            </table>
          </ScrollContainer>
        </div>
      )}
    </>
  );
}

export default Race;
