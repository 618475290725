import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../App";
import Server from "../../components/Server";

// -------------- STYLE -------------

import { Container, Row, Col } from "react-bootstrap";
import "./style.css";

import { ReactComponent as Add } from "../../img/icon/add.svg";
import { ReactComponent as LongArrow } from "../../img/icon/long-arrow.svg";
import { ReactComponent as Upload } from "../../img/icon/upload.svg";
import { ReactComponent as X } from "../../img/icon/x.svg";
import { ReactComponent as Tick } from "../../img/icon/tick.svg";
import Authenticated from "../../components/Authenticated";

function Home() {
  const [create, setCreate] = useState(false);
  const [races, setRaces] = useState([]);
  let history = useHistory();

  function logout() {
    new Server().logout(() => history.push(routes.login.publicUrl));
  }

  useEffect(() => {
    new Server().getRaces(({ races }) => setRaces(races));
  }, []);

  return (
    <Authenticated>
      <Container className="page" id="home">
        <br />
        <Row>
          <Col xs="8">
            <h1>CnvRaceDirection</h1>
          </Col>
          <Col xs="4" id="exit">
            <h6 onClick={logout}>Esci</h6>
          </Col>
        </Row>
        <br />
        <h5>
          Tutte le gare
          <button className="red" onClick={() => setCreate(!create)}>
            {!create ? (
              <h6>
                Crea <Add width="16px" height="16px" className="white mb-1" />
              </h6>
            ) : (
              <h6>
                Chiudi <X width="12px" height="12px" className="white mb-1" />
              </h6>
            )}
          </button>
        </h5>
        {create ? (
          <>
            <br />
            <CreateRace />
          </>
        ) : (
          ""
        )}
        <br />
        {races.length != 0 ? (
          <Row>
            {races.map((race, index) => (
              <Card key={index} content={race} />
            ))}
          </Row>
        ) : (
          <p>Non ho trovato nessuna gara...</p>
        )}
      </Container>
    </Authenticated>
  );
}

function Card(props) {
  let history = useHistory();
  let { id, name, date, race_code } = props.content;

  function openRace() {
    window.sessionStorage.setItem("raceCode", race_code);
    history.push(routes.race.publicUrl + race_code);
  }

  return (
    <Row className="race-card" id={race_code} onClick={openRace}>
      <Col xs="8">
        <h6>{name}</h6>
        <p>{date}</p>
      </Col>
      <Col xs="4" className="text-right centered">
        <LongArrow className="white" />
      </Col>
    </Row>
  );
}

function CreateRace() {
  const [penality, setPenality] = useState({ 0: "" });
  const [counter, setCounter] = useState(1);
  const [csvFile, setCsvFile] = useState();
  const [csvSetted, setCsvSetted] = useState(false);
  const [raceInfo, setRaceInfo] = useState({
    name: "",
    date: "",
    drivers: [],
  });

  function addPenality() {
    let penalityNumber = Object.keys(penality).length;
    if (penalityNumber < 8) {
      setPenality({ ...penality, [counter]: "" });
      setCounter(counter + 1);
    }
  }

  function deletePenality(name) {
    let penalityNumber = Object.keys(penality).length;
    if (penalityNumber > 1) {
      delete penality[name];
      setPenality({ ...penality });
    }
  }

  function penalityChangeHandler(e) {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setPenality({ ...penality, [inputName]: inputValue });
  }

  function changeHandler(e) {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setRaceInfo({ ...raceInfo, [inputName]: inputValue });
  }

  function createRace() {
    if (
      (raceInfo.name != "") &
      (raceInfo.date != "") &
      (raceInfo.drivers.length != 0)
    ) {
      let options = Object.values(penality).filter((item) => item != "");
      options = options.length > 0 ? options : [""];
      let data = {
        ...raceInfo,
        options: options,
      };
      new Server().createRace(
        () => (window.location.href = window.location.href),
        data
      );
    } else {
      alert("Compila tutti i campi: Nome, Data, CSV");
    }
  }

  useEffect(() => {
    if (csvFile != null) {
      let reader = new FileReader();
      reader.readAsText(csvFile);
      reader.onload = () => {
        let record = reader.result.split("\r\n");
        record = record.map((item) => [...item.split(",")]);
        let drivers = record
          .filter((item) => item.length == 3)
          .map((item) => {
            return { fullname: item[0], class: item[1], number: item[2] };
          });

        if (drivers.length != 0) {
          setRaceInfo({ ...raceInfo, drivers: drivers });
          setCsvSetted(true);
        } else {
          alert(
            "Non ho trovato piloti validi. \nControlla il formato e la formattazione del file."
          );
          setCsvSetted(false);
        }
      };
    }
  }, [csvFile]);

  return (
    <Row id="create-race" className="">
      <Col md="4" className="left">
        <h6>Generalità</h6>
        <input
          type="text"
          name="name"
          value={raceInfo.name}
          onChange={changeHandler}
          className="red-line mb-2"
          placeholder="Nome"
          autoComplete="off"
          autoCapitalize="on"
        />
        <br />
        <input
          type="text"
          name="date"
          value={raceInfo.date}
          onChange={changeHandler}
          className="red-line mb-3"
          placeholder="Data"
          autoComplete="off"
        />
        <br />
        <div className="text-center">
          <label htmlFor="csv" className="centered">
            <h6 className="mb-0 color-red">
              Carica CSV <Upload className="ml-2 mb-1" width="18" height="18" />
              {csvSetted ? (
                <Tick className="ml-2 mb-1" width="18" height="18" />
              ) : (
                ""
              )}
            </h6>
          </label>
          <input
            type="file"
            id="csv"
            name=""
            accept=".csv"
            autoComplete="off"
            autoCapitalize="on"
            onChange={(e) => setCsvFile(e.target.files[0])}
            hidden
          />
        </div>
      </Col>
      <Col md="8" className="right">
        <h6>Penalità</h6>
        <div className="three-rows">
          {Object.keys(penality).map((item, index) => (
            <div key={index} className="item">
              <input
                type="text"
                className="red-line mr-1 mb-2 item"
                autoComplete="off"
                name={item}
                value={penality[item]}
                onChange={penalityChangeHandler}
              />
              <div onClick={() => deletePenality(item)}>
                <X fill="white" width="10px" height="10px" />
              </div>
            </div>
          ))}
          {Object.keys(penality).length < 8 ? (
            <div>
              <h6 className="mt-2 mb-0 color-red" onClick={addPenality}>
                Aggiungi{" "}
                <Add fill="var(--second-color)" width="16" height="16" />
              </h6>
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
      <Col xs="12" className="text-right">
        <button className="red" id="save" onClick={createRace}>
          Salva
        </button>
      </Col>
    </Row>
  );
}

export default Home;
